import styled from "styled-components";

export const StyledCardCallToActionButton = styled.div`
  display: flex;
  justify-content: ${props => props.buttonAlign ? props.buttonAlign : "center"};
  margin-top: auto;
  max-width: 100%;

  a {
    cursor: pointer;
  }
`