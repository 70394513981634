import styled, { css } from 'styled-components';

export const StyledCardText = styled.div`
  display: ${props => props.text ? "block" : "none"};
  text-align: ${props => props.textAlign ? props.textAlign : "center"};
  font-family: Open Sans, sans-serif;
  color: #636463;
  line-height: 16px;
  max-width: ${props => props.isCountryCards ? '100%' : '338px'};

  ${props => 
    props.sizeSmall &&
      css`
        margin-bottom: 20px;

        @media(min-width: 640px) {
          margin-bottom: 0;
        }
      `
  }

  ${props =>
    props.desktopOneRowCard &&
    css`
      margin: ${props => {
        if (props.buttonComponent) {
          return "0 0 20px 0"
        } else if (props.isJetCardProduct) {
          return "50px 0 0 0"
        } else {
          return "0 0 50px 0"
        }
      }} ;
      max-width: 100%;

      @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
        margin-right: ${props => props.alignImage === "right" ? "5%" : "0" };
        margin-left: ${props => !props.isBlog && props.alignImage === "left" ? "5%" : "0" };
      }

      @media(min-width: 768px) {
        margin: 5%;
        max-width: unset;
        margin-right: ${props => props.alignImage === "right" ? "5%" : "0" };
        margin-left: ${props => !props.isBlog && props.alignImage === "left" ? "5%" : "0" };
      }

      @media(min-width: 1024px) {
        margin-right: ${props => props.alignImage === "right" ? "15%" : "0" };
        margin-left: ${props => !props.isBlog && props.alignImage === "left" ? "15%" : "0" };
      }
    `
  };

  p {
    text-align: ${props => props.textAlign ? props.textAlign : "center"};
    
    
    ${props =>
     props.desktopOneRowCard &&
      css`
        color: #3C3C3B;
        font-family: Raleway;
        font-size: 21px;
        letter-spacing: 0;
        line-height: 25px;
        text-align: left;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
          font-size: 14px;
        }

        @media(min-width: 768px) {
          font-size: clamp(16px, 2vw, 21px);
        }
      `
    };
  }

  p:nth-of-type(1) {
    margin-bottom: 10px;
    margin-bottom: ${props => props.textMarginBotttom ? props.textMarginBotttom : "10px"};
  }
`