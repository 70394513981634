import React from "react";
import PropTypes from "prop-types";
import { IconWrap } from '../styled'

const Arrow = ({ BtnMethod, img, imgArray, left }) => {
  return (
    <IconWrap 
      left={left}
      onClick={() => BtnMethod(img, imgArray)}
    >
      {
        left ?
          <span className='left-arrow-icon' />
        :
          <span className='right-arrow-icon' />
      }
    </IconWrap>
  );
};

Arrow.propTypes = {
  BtnMethod:PropTypes.func,
  img: PropTypes.string,
  imgArray: PropTypes.array,
  left: PropTypes.bool
}

export default Arrow;
