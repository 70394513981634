import React from 'react'
import PropTypes from 'prop-types'

import { StyledButtonIcon, StyledButtonLink, StyledButton } from 'components/buttons/ArrowButton/styled';

function ArrowButton({ buttonText, buttonHref, theme, isLink, onClick, style, language }) {
  if (isLink) {
    return (
      <StyledButtonLink  
        path={buttonHref}
        language={language ? language : "en-gb"}
        theme={theme}
        style={style ? style : {}}
        linkComponent={
          <React.Fragment>
            {buttonText} 
            <StyledButtonIcon theme={theme}>
              <span className='right-arrow-button-icon' />
            </StyledButtonIcon> 
          </React.Fragment>
        }

      />
      
    )
  } else {
    return (
      <StyledButton 
        onClick={onClick} 
        theme={theme}
        style={style ? style : {}}
      >
        {buttonText} 
        <StyledButtonIcon theme={theme}>
          <span className='right-arrow-button-icon' />
        </StyledButtonIcon>
      </StyledButton>
    )
  }

}

ArrowButton.propTypes = {
  buttonHref: PropTypes.string, 
  buttonText: PropTypes.string, 
  isLink: PropTypes.bool, 
  language: PropTypes.string, 
  onClick: PropTypes.func, 
  style: PropTypes.object,
  theme: PropTypes.string
}

export default ArrowButton

