import styled, { css } from 'styled-components';

export const StyledHeadingWrapper = styled.div`
  max-width: ${props => props.isBlog && props.isFeaturedBlogCard ? "420px" : "338px"};

  h2 {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px;
    color: #3C3C3B;  
    font-weight: 400;
    margin-top: 37px;
  }

  h4 {
    font-family: Raleway, sans-serif !important;
    text-align: ${props => props.headingTextAlign ? props.headingTextAlign : "center"};
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px;
    color: #3C3C3B;
    font-weight: 400;
    margin: 24px 0;

    ${props => 
      props.sizeSmall &&
        css`
          margin: 20px 0 12px 0;
          font-size: 21px;
        `
    }
  }

`