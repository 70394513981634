import styled, { css } from 'styled-components';

export const StyledCardBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props => props.isFakeCard &&
    css`
      height: 0;
      margin: 0;
    `
  }
  
  ${props => props.desktopOneRowCard &&
    css`
      width: 100%;
      height: auto;
      display: initial;
      margin-top: ${props => props.buttonComponent ? "40px": "0"};

      max-width: ${props => props.isBlog && "358px"};
      

      @media(min-width: 768px) {
        width: 50%;
        margin-top: 0;
        ${'' /* height: clamp(170px, 25vw, 340px); */}
        height: 100%;
        max-height: ${props => props.isBlog && !props.isFeaturedBlogCard ? "170px": "unset"};
        max-width: ${props => props.isBlog && "unset"};
      }

      @media(min-width: 1024px) {
        ${'' /* height: 100%; */}
      }
    `
  }
`