import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';

import ArrowIcon from '../icons/Arrow';
import { ModalImageStyle, ModalWrap, ModalCloseButton, StyledModalOpenInNewTab, StyledModalZoomCloseButton} from '../styled';

const GalleryModal = ({open, onCloseModal, imgArray, img, onBackBtn, onForwardBtn, isZoomCard}) => {
    return (
      <Modal
        open={open}
        onClose={onCloseModal}
        center={true}
        showCloseIcon={false}
        closeIconSize={20}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'custom-modal',
          closeButton: 'modal-close-btn',
          closeIcon: 'modal-close-icon'
        }}
      >
        <ModalWrap>
          
          {imgArray.length > 1 && (
            <ArrowIcon 
              img={img}
              imgArray={imgArray}
              BtnMethod={onBackBtn}
              left={true}
            />
          )}
          
          <ModalImageStyle
            src={img}
            width='100%'
            height='100%'
            maxWidth='846px'
            maxHeight='564px'
          />
          
          {imgArray.length > 1 && (
            <ArrowIcon
              img={img}
              imgArray={imgArray}
              BtnMethod={onForwardBtn}
              left={false}
            />
          )}

          {isZoomCard ? (
            <React.Fragment>
              <StyledModalOpenInNewTab>
                <a 
                  href={img} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img 
                    src="https://images.prismic.io/privatefly/a5065cea-cbb6-453f-b2b7-2734b40c07fd_open_in_new_tab.png?auto=compress,format" 
                    alt='open in new tab' 
                  />
                </a>
              </StyledModalOpenInNewTab>
              <StyledModalZoomCloseButton onClick={onCloseModal} />
            </React.Fragment>
          
          ) : (
            
            <ModalCloseButton onClick={onCloseModal} />

          )}
          
        </ModalWrap>
      </Modal>
    );
};

GalleryModal.propTypes = {
    img: PropTypes.string,
    imgArray: PropTypes.array,
    isZoomCard: PropTypes.bool,
    onBackBtn: PropTypes.func,
    onCloseModal: PropTypes.func,
    onForwardBtn: PropTypes.func,
    open: PropTypes.bool,
};

export default GalleryModal;