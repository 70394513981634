import styled from 'styled-components';

import InternalLink from 'components/links/internalLink';

export const StyledButtonLink = styled(InternalLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: 600;
  color: ${props => props.theme === "red" ? "#3C3C3B" : "#4990E2"};
  cursor: pointer;
  text-transform: uppercase;

  &:visited { 
    text-decoration: none; 
    color: ${props => props.theme === "red" ? "#3C3C3B" : "#4990E2"};
  } 

  &:hover { 
    text-decoration: none; 
    color: ${props => props.theme === "red" ? "#3C3C3B" : "#4990E2"};
  }
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: 600;
  color: ${props => props.theme === "blue" ? "#4990E2" : "#3C3C3B"};
  cursor: pointer;
  text-transform: uppercase;
  background: transparent;
  border: none;

  &:hover { 
    text-decoration: none; 
    color: ${props => props.theme === "blue" ? "#4990E2" : "#3C3C3B"};
  }
`

export const StyledButtonIcon = styled.div`
  position:relative;
  width: 28px;
  height: 28px;
  border-radius: 24.5px;
  background-color: ${props => props.theme === "red" ? "#E30613" : "#DBE9F9"};
  margin-left: 10px;

  .right-arrow-button-icon {
    position: absolute;
    border: ${props => props.theme === "red" ? "solid #fff" : "solid #4990E2"};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    top: 50%;
    right: 26%;
    width: 6px;
    height: 6px;
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
  }
`