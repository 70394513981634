import styled, { css } from 'styled-components';

import InternalLink from 'components/links/internalLink';

export const StyledCard = styled.div`
  position: relative;
  max-width: ${props => props.isCountryCards ? '100%' : '338px'};
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 61px;
  display: flex;
  flex-direction: column;

  ${props => props.isCountryCards &&
    css`
      @media(min-width: 640px) {
        img {
          height: 200px !important;
          object-fit: cover !important;
        }
      }
    `
  }

  ${props => props.isFakeCard &&
    css`
      height: 0;
      margin-bottom: 0;
      visibility: hidden;
    `
  }

  @media(min-width: 640px) {
    flex: ${props => props.desktopOneRowCard ? "100%" : "0 1 calc(50% - 1em)"};
  }

  ${props => 
    !props.sizeSmall && !props.desktopOneRowCard && 
      css`
        &:last-of-type {
          margin-right: ${props => props.lastCardMargin ? props.lastCardMargin : "auto" };
          margin-left: ${props => props.lastCardMargin ? props.lastCardMargin : "auto" };
        }

        @media(min-width: 992px) {
          &:last-of-type {
            margin-right: unset;
            margin-left: unset;
          }
        }
      `
    }

  @media(min-width: 992px) {
    flex: ${props => props.desktopOneRowCard ? "100%" : !props.isCountryCards && "0 1 calc(33% - 1em)"}; 
    margin : ${props => props.margin && `${props.margin} !important` };
    padding-right: ${props => props.isCountryCards && '30px' };

    &:last-of-type {
      margin-right: ${props => props.sizeSmall && props.hasSeperator ? "20px" : "0"};
      margin-left: unset;
    }
  }

  @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
    flex: ${props => props.desktopOneRowCard ? "100%" : "0 1 calc(50% - 1em)"};
  }

  ${props => 
    props.sizeSmall &&
      css`
        min-width: 246px;
        max-width: 246px;
        margin-bottom: 30px;
        margin-right: ${props => props.hasSeperator ? "0" : "0"};
        border-top: 1px solid #EFEFEF;

        @media(min-width: 640px) {
          margin-right: 20px;
        }
          
        @media(min-width: 1200px) {
          margin-right: ${props => props.hasSeperator ? "45px" : "20px"};

          &:nth-child(4n) {
            margin-right: 0;
          }

          &:last-of-type {
            margin-left: unset;
          }
        }

        @media(min-width: 1440px) {
          margin-right: ${props => props.hasSeperator ? "75px" : "90px"};

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      `
  }

  ${props =>
    props.desktopOneRowCard &&
    css`
      flex-direction: ${props => {
        if (props.buttonComponent || props.isJetCardProduct) {
          return "column"
        } else {
          return "column-reverse"
        }
      }};
      margin: ${props => props.isJetCardProduct ? "30px 0 0 0" : "60px 0 0 0" };
      flex: 100%;
      max-width: 100%;
      align-items: center;
      justify-content: ${props => props.zoomOption === true ? "space-between" : "initial"};
      margin : ${props => props.margin && `${props.margin} !important` };

      @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
        flex-direction: ${props => {
          if (props.alignImage === "left") return "row"
          else if (props.alignImage === "right") return "row-reverse"
          else return "row"

        }};
      }

      @media(min-width: 768px) {
        flex-direction: ${props => {
          if (props.alignImage === "left") return "row"
          else if (props.alignImage === "right") return "row-reverse"
          else return "row"

        }};
        flex: 100%;
        height: ${props => props.zoomOption === true ? "450px" : "unset"};
        max-width: unset;
        width: 100%;
      }

      @media(min-width: 1024px) {
        margin-top: 80px;
      }
    `
  };

  ${props => props.sizeSmall && props.hasSeperator &&
    css`
        &:not(:last-of-type):after {
          @media(min-width: 1200px) {
            content: "";
            position: absolute;
            height: 115%;
            width: 1px;
            top: -7.5%;
            right: -22.5px;
            background: #D4D6DE;
          }

          @media(min-width: 1440px) {
            right: -37.5px;
          }
        }
      `
  } 

  @media(min-width: 1336px) {
    ${props => !props.sizeSmall && props.hasSeperator &&
      css`
        &:not(:last-of-type):after {
          content: "";
          position: absolute;
          height: 115%;
          width: 1px;
          top: -7.5%;
          right: -60px;
          background: #D4D6DE;
        }
      `
    }
  } 

  .card-zoom-button {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }
`

export const StyledCardLink = styled(InternalLink)`
  position: relative;
  max-width: 338px;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 61px;
  display: flex;
  flex-direction: column;

  ${props => props.isFakeCard &&
    css`
      height: 0;
      margin-bottom: 0 !important;
      visibility: hidden;
    `
  }

  @media(min-width: 640px) {
    flex: ${props => props.desktopOneRowCard ? "100%" : "0 1 calc(50% - 1em)"};
  }

  ${props => 
    !props.sizeSmall && !props.desktopOneRowCard && 
      css`
        &:last-of-type {
          margin-right: auto;
          margin-left: auto;
        }

        @media(min-width: 992px) {
          &:last-of-type {
            margin-right: unset;
            margin-left: unset;
          }
        }
      `
    }

  @media(min-width: 992px) {
    flex: ${props => props.desktopOneRowCard ? "100%" : "0 1 calc(33% - 1em)"};

    &:last-of-type {
      margin-right: ${props => props.sizeSmall && props.hasSeperator ? "20px" : "20px"};
      margin-left: unset;
    }
  }

  @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
    flex: ${props => props.desktopOneRowCard ? "100%" : "0 1 calc(50% - 1em)"};
  }

  ${props => 
    props.sizeSmall &&
      css`
        min-width: 246px;
        max-width: 246px;
        margin-bottom: ${props => props.isCarousel ? "0" : "30px"};
        ${'' /* margin-right: ${props => props.hasSeperator ? "0" : "0"}; */}
        margin-right: ${props => {
          if (props.hasSeperator) return "0"
          else if (props.isCarousel) return "40px"
        }};
        border-top: 1px solid #EFEFEF;

        @media(min-width: 640px) {
          margin-right: 20px;
        }
          
        @media(min-width: 1200px) {
          margin-right: ${props => props.hasSeperator ? "45px" : "20px"};

          &:nth-child(4n) {
            margin-right: 0;
          }

          &:last-of-type {
            margin-left: unset;
          }
        }

        @media(min-width: 1440px) {
          margin-right: ${props => props.hasSeperator ? "75px" : "90px"};

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      `
  }

  ${props =>
    props.desktopOneRowCard &&
    css`
      flex-direction: ${props => props.isBlog ? "column" : "column-reverse"};
      margin: ${props => props.isBlog && !props.isFeaturedBlogCard ? "0 0 100px 0" : "60px 0 0 0"};
      flex: 100%;
      max-width: 100%;
      align-items: center;
      justify-content: ${props => props.isBlog && !props.isFeaturedBlogCard || props.zoomOption === true ? "space-between" : "initial"};
      ${'' /* max-height: 340px; */}

      &:last-of-type {
        margin: ${props => props.isBlog && !props.isFeaturedBlogCard && "0 0 40px 0"};
      }

      @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
        flex-direction: ${props => {
          if (props.alignImage === "left") return "row"
          else if (props.alignImage === "right") return "row-reverse"

        }};
      }

      @media(min-width: 768px) {
        flex-direction: ${props => {
          if (props.alignImage === "left") return "row"
          else if (props.alignImage === "right") return "row-reverse"

        }};
        flex: 100%;
        height: ${props => props.zoomOption === true ? "450px" : "unset"};
        max-width: unset;
        width: 100%;
        &:last-of-type {
          margin: ${props => props.isBlog && !props.isFeaturedBlogCard ? "0 0 100px 0" : "60px 0 0 0"};
        }
      }
    `
  };

  ${props => props.sizeSmall && props.hasSeperator &&
    css`
        &:not(:last-of-type):after {
          @media(min-width: 1200px) {
            content: "";
            position: absolute;
            height: 115%;
            width: 1px;
            top: -7.5%;
            right: -22.5px;
            background: #D4D6DE;
          }

          @media(min-width: 1440px) {
            right: -37.5px;
          }
        }
      `
  } 

  @media(min-width: 1336px) {
    ${props => !props.sizeSmall && props.hasSeperator &&
      css`
        &:not(:last-of-type):after {
          content: "";
          position: absolute;
          height: 115%;
          width: 1px;
          top: -7.5%;
          right: -60px;
          background: #D4D6DE;
        }
      `
    }
  } 
  cursor: pointer;
  text-decoration: none;

  &:visited { 
    text-decoration: none;
    color: initial;
  }

  &:hover { 
    text-decoration: none;
    color: initial;
  }
`

// export const StyledCardLink = styled(ExtendedStyledCard)``