import React from 'react'
import PropTypes from 'prop-types'

import { StyledHeadingWrapper } from 'components/card/CardTitle/styled';

function CardTitle({ headingText, headingTextAlign, sizeSmall, isBlog, isFeaturedBlogCard }) {
  return (
    <StyledHeadingWrapper 
      headingTextAlign={headingTextAlign}
      sizeSmall={sizeSmall}
      isBlog={isBlog}
      isFeaturedBlogCard={isFeaturedBlogCard}
    >
      {headingText}
    </StyledHeadingWrapper>
  )
}

CardTitle.propTypes = {
  headingText: PropTypes.string,
  headingTextAlign: PropTypes.string,
  isBlog: PropTypes.bool,
  isFeaturedBlogCard: PropTypes.bool,
  sizeSmall: PropTypes.bool,
}

export default CardTitle

