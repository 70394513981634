import React from 'react'
import PropTypes from 'prop-types'

import { StyledCardCallToActionButton } from 'components/card/CardButton/styled';

function CardButton({ buttonComponent, buttonAlign, additionalButtonStyle }) {
  return (
    <StyledCardCallToActionButton 
      buttonAlign={buttonAlign}
      style={additionalButtonStyle && additionalButtonStyle}
    >
      {buttonComponent}
    </StyledCardCallToActionButton>
  )
}

CardButton.propTypes = {
  additionalButtonStyle: PropTypes.object,
  buttonAlign: PropTypes.string,
  buttonComponent: PropTypes.object,
}

export default CardButton

