import React from 'react'
import PropTypes from 'prop-types'

import { StyledCardText } from 'components/card/CardText/styled';

function CardText({ 
  text, 
  desktopOneRowCard, 
  alignImage, 
  sizeSmall, 
  textAlign, 
  buttonComponent, 
  isJetCardProduct, 
  textMarginBotttom,
  isBlog,
  isFeaturedBlogCard,
  isCountryCards
}) {
  return (
    <StyledCardText 
      desktopOneRowCard={desktopOneRowCard}
      alignImage={alignImage}
      sizeSmall={sizeSmall}
      textAlign={textAlign}
      text={text}
      buttonComponent={buttonComponent}
      isJetCardProduct={isJetCardProduct}
      textMarginBotttom={textMarginBotttom}
      isBlog={isBlog}
      isFeaturedBlogCard={isFeaturedBlogCard}
      isCountryCards={isCountryCards}
    >
      {text}
    </StyledCardText>
  )
}

CardText.propTypes = {
  alignImage: PropTypes.string,
  buttonComponent: PropTypes.object,
  desktopOneRowCard: PropTypes.bool,
  isBlog: PropTypes.bool,
  isCountryCards: PropTypes.bool,
  isFeaturedBlogCard: PropTypes.bool, 
  isJetCardProduct: PropTypes.bool,
  sizeSmall: PropTypes.bool,
  text: PropTypes.string,
  textAlign: PropTypes.string,
  textMarginBotttom: PropTypes.string,
}

export default CardText

