import React, { useState } from 'react'
import PropTypes from 'prop-types'

import GalleryModal from 'components/galleries/modalGallery/modal' 

import { StyledCardImageWrapper } from 'components/card/CardImage/styled';

const isFR = process.env.LANG_CODE === 'fr-fr';

function CardImage({ 
  imageSrc, 
  imageAltText, 
  hideImageInMobile, 
  desktopOneRowCard,
  zoomOption,
  zoomButtonText,
  zoomButton,
  isFakeCard,
  sizeSmall,
  isBlog,
  objectFitCover,
  isFeaturedBlogCard,
}) {
  const [ shouldZoom, zoomImage ] = useState(false)

  const Button = zoomButton

  const handleImageZoom = () => {
    zoomImage(!shouldZoom)
  }

  return (
    <StyledCardImageWrapper 
      className="card-image"
      alt={imageAltText}
      hideImageInMobile={hideImageInMobile}
      desktopOneRowCard={desktopOneRowCard}
      zoomOption={zoomOption}
      zoomButtonText={zoomButtonText}
      shouldZoom={false}
      isFakeCard={isFakeCard}
      sizeSmall={sizeSmall}
      isBlog={isBlog}
      isFeaturedBlogCard={isFeaturedBlogCard}
      objectFitCover={objectFitCover}
    >
      <img 
        src={imageSrc}
        alt={imageAltText}
      />
      {zoomOption === true && (
        <Button 
          isLink={false}
          size={"small"}
          buttonText={shouldZoom === false ? zoomButtonText : isFR ? "FERMER" : "CLOSE"}
          padding="45px"
          className="card-zoom-button"
          onClick={() => handleImageZoom()}
          isZoomButton={true}
        /> 
      )}
      { shouldZoom === true && (
        <GalleryModal 
          open={shouldZoom}
          onCloseModal={()=>zoomImage(false)}
          imgArray={[]}
          img={imageSrc}
          isZoomCard={true}
        /> 
      )}
    </StyledCardImageWrapper>
  )
}

CardImage.propTypes = {
  desktopOneRowCard: PropTypes.bool,
  hideImageInMobile: PropTypes.bool,
  imageAltText: PropTypes.string,
  imageSrc: PropTypes.string,
  isBlog: PropTypes.bool,
  isFakeCard: PropTypes.bool, 
  isFeaturedBlogCard: PropTypes.bool,
  objectFitCover: PropTypes.bool,
  sizeSmall: PropTypes.bool, 
  zoomButton: PropTypes.object,
  zoomButtonText: PropTypes.string,
  zoomOption: PropTypes.bool,
}

export default CardImage

