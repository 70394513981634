import styled, { css } from 'styled-components';

export const MobileWrap = styled.section`
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const StyledGalleryWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;

  ${props => (props.length === 7 || props.length === 3) &&
      css`
        @media (min-width: 832px) {
          :after {
            content: '';
            flex-grow: 0.5 !important;
            flex-basis: 20% !important;
          }
        }
    `}
    
  @media (min-width: 832px) {
    :after {
      content: '';
      flex-grow: 0.87;
      flex-basis: 5%;
    }
  }

  @media (min-width: 1024px) {
    :after {
      content: '';
      flex-grow: 0.93;
      flex-basis: 15%;
    }
  }

  img, .empty {
    width: 100%;
    height: 100%;
    margin-bottom: 0.75em;
    cursor: pointer;

    @media screen and (min-width: 40em) {
      width: 49%;
    }

    @media screen and (min-width: 52em) {
      width: 32%;
    }

    @media screen and (min-width: 1024px) {
      height: 152px;
      max-width: 21%;
      margin-right: 30px;
      margin-bottom: 25px;
      
      &:nth-of-type(4n) {
        margin-right: 0;
      }
      &:last-of-type{
        margin-right: 0;
      }
    }

    @media screen and (min-width: 1200px) {
      height: 102px;
      max-width: 153px;
    }
    
  }

  .empty {
    /* border: 1px solid blue; */
    visibility: hidden;
  }
`;

export const ExtraImgStyle = styled.section`
  position: absolute;
  right: 4%;
  bottom: 6%;
  cursor: pointer;
  color: white;
  z-index: 100;
  width: 135px;
  height: 30px;
  font-size: 18px;
  background: black;
  opacity: 0.9;
  border-radius: 5px;
  text-align: center;
  font-family: Raleway, 'sans-serif';

  @media screen and (device-aspect-ratio: 40/71) {
    bottom: 8%;
  }
`;

export const IconWrap = styled.section`
  width: 150px;
  height: 100%;
  position: absolute;
  bottom: 0;
  cursor: pointer;

  ${props =>
    props.left &&
    css`
      left: 0 !important;
    `};
  ${props =>
    !props.left &&
    css`
      right: 0 !important;
    `};
`;

export const SingleImgWrap = styled.section`
 min-width: 300px;
 text-align: center;

 @media (min-width: 769px) {
   text-align:left;
 }
 `

export const ModalWrap = styled.div`

`

export const ModalImageStyle = styled.img`
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "100%")};
  maxWidth: ${props => (props.maxWidth ? props.maxWidth : "846px")};
  maxHeight: ${props => (props.maxHeight ? props.maxHeight : "564px")};
`

export const ModalCloseButton = styled.span`
  position: absolute;
  right: 0.6em;
  top: 0.6em;
  cursor: pointer;
  color: #e30613;
  border: 2px solid #cacaca;
  border-radius: 40px;
  background: white;
  font-size: 30px;
  font-weight: 400;
  display: inline-block;
  line-height: 0px;
  padding: 13px 4px;

  :before {
    content: '×';
  }

  :hover {
    border: 2px solid #e30613;
  }
`

export const StyledModalOpenInNewTab = styled(ModalCloseButton)`
  right: 0em;
  top: 0em;
  padding: 2px;
  border-radius: 0px;
  display: none;
  
  img {
    width: 30px;
  }

  @media (max-width: 1024px) {
    display: block;
  }

  :before {
    content: '';
  }
  
  :hover {
    border: 2px solid #cacaca;
  }
`;

export const StyledModalZoomCloseButton = styled(ModalCloseButton)`
  @media (max-width: 1024px) {
    border-radius: 0px;
    font-size: clamp(12px, 10vw, 15px); 
    font-weight: 600;
    padding: 12px;
    right: 0px;
    bottom: 0px;
    top: unset;

    :before {
      content: 'CLOSE';
    }    
  }
`;

export const StyledModalCloseBtn = styled.span`
  position: absolute;
  right: 0.4em;
  top: 0.3em;
  cursor: pointer;
  color: #e30613;
  border: 2px solid #cacaca;
  border-radius: 40px;
  background: white;
  font-size: 30px;
  font-weight: 400;
  display: inline-block;
  line-height: 0px;
  padding: 13px 4px;

  :before {
    content: '×';
  }

  :hover {
    border: 2px solid #e30613;
  }
`;